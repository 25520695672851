.header {
    line-height: 28.80px;
    @apply
    text-gray-800
    text-2xl
    font-bold
    text-center
}

.steps {
    @apply
    text-center
}
.banks {
    @apply
    pt-4
}
.bank {
    width: 210px;
    height: 72px;
    @apply
    ml-2
}
.bank:hover > img {
    filter: invert(1);
}