.frame {
  background: #ffffff !important;
  max-width: 550px;
  @apply
    rounded-xl
    text-left
    p-8
    mt-10
}
// was 464
//Form w-[464px] h-[550px] relative
