.container {
  border: 1px solid #000000;
  border-radius: 14px;
  @apply inline-flex
      align-middle
      w-full
      m-3
      p-2
      relative
}

.label_placeholder {
  @apply h-6 p-0 left-5 -top-3 absolute bg-white justify-center items-center inline-flex;
}

.label_placeholder div {
  font-size: 13px;
  @apply 
  text-blue-500 
  font-normal
}