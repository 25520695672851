.footer {
  z-index: 50 !important;
  @apply
  relative
  bottom-0
  w-full
  m-0
}

.container {
  padding-left: 40px !important;
  padding-right: 40px !important;
  min-height: 189px;
  line-height: 200%;
  @apply
  bg-gray-800
  text-white
  p-4
}

.leftblock {
}
/*
.footer {
  width: 100%;
  @apply flex-shrink-0
    h-[189px] 
    bg-gray-800
    relative;
}
*/
/*
    left-0 
    bottom-0
    absolute 
*/
.logo {
  @apply
  h-6
  w-48
  m-4
}

.description {
  opacity: 0.3 !important;
  @apply 
  text-base 
  font-normal 
  leading-relaxed;
}

.copyright {
  opacity: 0.3 !important;
  @apply 
  text-sm 
  font-normal 
  leading-snug;
}

.contacts {
  opacity: 0.3;
  line-height: 1.5rem !important;
  @apply
  text-base
  font-normal
}

.title {
  @apply left-0 top-0 absolute text-white text-opacity-30 text-base font-normal font-['Roboto'] leading-relaxed;
}

.email {
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 15px !important;
  padding-left: 0 !important;
  @apply
  font-normal
}

.email:hover {
  color: #ffffff !important;
}
