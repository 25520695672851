.bg {
    width: 298px;
    height: 299px;
    top: 50vh;
    z-index: 1;
    @apply
    absolute
    overflow-visible 
    left-0 
    
}

.r1 {
    width: 100px;
    height: 100px;
    top: 199px;
    @apply
    left-0 
    absolute
    bg-slate-200
}

.r2 {
    width: 100px;
    height: 100px;
    left: 100px;
    top: 100px;
    @apply
    absolute 
    bg-slate-200
}

.r3 {
    width: 100px;
    height: 100px;
    @apply
    left-0 
    top-0 
    absolute 
    bg-slate-200
}

.r4 {
    width: 99px;
    height: 100px;
    left: 199px;
    @apply
    top-0
    absolute
    bg-slate-200
}