.input
{
    font-size: 19px;
    @apply
    w-full
    h-full
}
.suffiximg {
    @apply
    ml-2
    mr-2
    h-6
}
.flabel {
    position: fixed;
    top:0;
    color: #A9A9A9;
}