.container {
  @apply inline-flex
    align-middle
    items-center
    w-full
    m-1;
}
.header {
  @apply ml-2.5
    text-gray-800
    text-xl
    font-bold;
}
.numberparent {
  @apply w-8
    h-8
    bg-blue-500 
    relative
    rounded-full
    text-white;
}

.number {
  @apply text-base
    font-bold
    leading-tight
    left-3 
    top-1.5
    absolute;
}
