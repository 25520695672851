.mainheader {
    grid-area: header;
    height: 84px;
    padding-left: 40px !important;
    padding-right: 40px !important;
    z-index: 50;
    border-color: #c7cbdb !important;
    @apply
    p-2
    pt-6
    pb-6
    border-0
    border-b
    border-solid
  }
  
  .logo {
    @apply
    h-8
  }

  .navlink {
    font-size: 19px;
    color: #797d86;
    @apply
    ml-6
    font-normal
    text-base
  }
 
  .active {
    color: #2293FB;
  }
  .active:hover {
    color: #2293FB;
  }
  .active:focus {
    color: #2293FB;
  }
  .active:visited {
    color: #2293FB;
  }

  .navbutton {
    font-size: 19px;
    @apply
    text-gray-800
    text-base
    font-semibold
    pt-2
    ml-6
    rounded-2xl
  }