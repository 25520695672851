.bg {
    width: 299px;
    height: 299px;
    top: 150px;
    z-index: 0;
    @apply
    absolute
    right-0
    bottom-auto
}

.r1 {
    width: 99px;
    height: 100px;
    top: 0px;
    @apply
    left-0
    absolute
    bg-slate-200
}

.r2 {
    width: 100px;
    height: 99px;
    left: 99px;
    top: 99px;
    @apply
    absolute 
    bg-slate-200
}

.r3 {
    width: 99px;
    height: 100px;
    left: 199px;
    top: 0px;
    @apply 
    absolute
    bg-slate-200
}

.r4 {
    width: 100px;
    height: 100px;
    left: 199px;
    top: 199px;
    @apply
    absolute 
    bg-slate-200
}