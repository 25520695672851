.header {
    font-size: 28px;
    line-height: 33.60px;
    @apply
    text-gray-800
    font-bold
}

.accordion > button:not(.collapsed) {
    background-color: #ffffff;
    box-shadow: none;
}